const lang = {
    words: {
        id: 'Id',
        license: 'Licencia',
        username: 'Usuario',
        password: 'Contraseña',
        rePassword: 'Repita la contraseña',
        rememberMe: 'Recordarme en este dispositivo',
        basket: 'Cesta',
        vat: 'Num. fiscal (DNI, CIF, NIE)',
        company: 'Empresa',
        name: 'Nombre',
        surname: 'Apellidos',
        email: 'Email',
        phone: 'Teléfono',
        street: 'Calle',
        number: 'Número',
        city: 'Población',
        state: 'Provincia',
        postalCode: 'Código postal',
        country: 'País',
        streetNumber: 'Número',
        total: 'Total',
        taxBase: 'Base imponible',
        optional: 'Opcional',
        attachments: 'Adjuntos',
        available: 'Disponible',
        product: 'Producto',
        productsCount: ' 1 producto | {count} productos',
        generateInvoice: 'Solicitar factura (opcional)',
        privacyPolicy: 'Política de privacidad.',
        termsAndConditions: 'Aviso legal.',
        beforeTermsAndConditions: 'Acepto las',
        beforePrivacyPolicy: 'Acepto la',
        beforePrivacyPolicys: 'Acepto los',
        generalConditions: 'Condiciones generales y de uso',
        andThe: 'y el',
        voucher: 'Vale',
        comment: 'Comentario',
        code: 'Código',
        occupied: 'Ocupadas',
        maximum: 'Máximo',
        quant: 'Cant.',
        price: 'Precio',
        minuts: 'Minutos',
        newCustomer: '¿Eres un cliente nuevo?',
        startHere: 'Empieza aquí',
        registeredCustomer: '¿Ya tienes una cuenta?',
        recoveryPassword: 'Introduzca la dirección de correo electrónico para recuperar su contraseña',
        newPassword: 'Introduzca una nueva contraseña',
        date: 'Fecha',
        status: 'Estado',
        paymentDate: 'Fecha de pago',
        paymentMethod: 'Método de pago',
        orderDetails: 'Detalles del pedido',
        refundDetails: 'Detalles de reembolso',
        unitPrice: 'Precio unidad',
        quantity: 'Cantidad',
        discount: 'Descuento',
        edit: 'Editar',
        delete: 'Eliminar',
        save: 'Guardar',
        cancel: 'Cancelar',
        close: 'Cerrar',
        modify: 'Modificar',
        privacyPolicyLink: '/politica-de-venta-y-cancelacion-devolucion/',
        termsAndConditionsText: 'términos y condiciones',
    },
    messages: {
        loading: 'Cargando...',
        xOfYAvailable: 'disponibles {x} de {y}',
        selectBookingdate: 'Selecciona la fecha de la reserva',
        signinIn: 'Iniciando sesión',
        loginFail: 'Fallo al iniciar sesión',
        loginFailMessage: 'Verifique sus credenciales y vuelva a intentarlo',
        fileIsNotImage: 'El archivo no es una imagen',
        paymentOK: 'Pedido pagado!',
        paymentKO: 'No se ha podido realizar el pago.',
        paymentOKText: 'Enviaremos al correo electrónico facilitado, la confirmación de pedido. Hasta pronto.',
        iHaveATicketOrGiftVoucher: 'Tengo un bono o cheque regalo',
        writeHerePlaceholder: 'Escriba aquí ...',
        availableBookings: 'Reservas disponibles',
        amountExceeded: 'Cantidad superada',
        amountExceededText: 'Quedan {maxUnits} reservas disponibles para esta fecha y usted quiere reservar {quantity}. Si necesita realizar más reservas, deberá buscar otra fecha con más disponibilidad.',
        incorrectCode: 'Código incorrecto',
        incorrectCodeText: 'Por favor compruebe el código introducido',
        validateYourEmail: 'Valide su email a través del email que ha recibido en su cuenta.',
        registrationError: 'Ne se a podido registrar el usuario',
        registrationFail: 'Registro fallido',
        registrationFailMessage: 'Revisa tus datos y vuelvelo a intentar',
        customerAlreadyExists: '¡Parece que ya estás registrado!',
        passwordConfirmationNotMatch: 'La confirmación de la contraseña no coincide',
        sentEmailRecovery: 'Email enviado',
        sentEmailRecoveryText: 'Te hemos mandado un email para recuperar tu contraseña.',
        sentEmailRecoveryFail: 'Se ha producido un error.',
        sentEmailRecoveryFailText: 'Intentalo de nuevo más tarde.',
        emailNotExists: 'El email no existe.',
        emailAlreadyExists: 'El email ya existe.',
        emptyBasket: 'No hay productos en el carrito.',
        noBookingPossible: 'No se puede reservar',
        theBookingOf: 'La reserva de ',
        theDay: ' el día ',
        noFurtherBookingsAccepted: 'no admite más reservas. Por favor, reserve de nuevo en un horario distinto.',
        errorCreatingOrder: 'Error creando el pedido',
        thereSeemsToHaveAProblemWhenPlacingOrder: 'Parece que ha habido un problema al realizar el pedido. Inténtelo de nuevo más tarde o contacte con nosotros.',
        bookingConfirmed: 'Reserva confirmada',
        bookingIsConfirmedText: 'Confirmamos que su reserva se ha registrado correctamente.',
        giftVouchersAreIndividual: 'El cheque regalo que está utilizando es individual, no puede usarlo para más de una reserva en una misma hora.',
        errorTryingToBook: 'Error intentado reservar',
        errorTryingToBookMessage: 'Ha surgido un problema al realizar esta reserva. Inténtelo de nuevo más tarde o contacte con nosotros.',
        emptyOrders: 'No hay pedidos.',
        emptyModifiableBookings: 'No hay reservas modificables.',
        cancellationRequest: 'Para solicitar la cancelación de un pedido contactar a través del email',
        cancellationRequest2:  '.(No se podrá cancelar la visita antes de 24h)',
        soldOut: 'Agotado',
        lastUnits: 'Últimas unidades',
        available: 'Disponible',
        notModifiable: 'No modificable',
        bookingDateChanged: 'Fecha de reserva cambiada',
        chooseTheTourYouWantToTake: '¿Te han regalado una visita con nosotros?',
        checkHereAndMakeYourReservation: 'Tengo un cheque regalo',
        enterTheCode: 'Introduce el código',
        highAvailavility: 'Disponibilidad alta',
        mediumAvailavility: 'Disponibilidad media',
        noAvailavility: 'No hay disponibilidad',
        acceptTheTerms: 'Acepta los términos de venta y la política de privacidad',
        haveToAcceptTheTerms: 'Para poder continuar con la compra, debes aceptar los términos de venta y la política de privacidad.',
        missingPaymentEntries: 'Faltan entradas de pago',
        guidedTours: 'La reserva de visitas guiadas gratuitas está vinculada a la compra de entradas de pago. Por favor, añade entradas de pago al carrito para poder continuar con la compra.',
        ok: 'De acuerdo',
        myProfile: 'Mi perfil',
        modifyBookings: 'Modificar reservas',
        myOrders: 'Mis pedidos',
        changePassword: 'Cambiar contraseña',
        logOut: 'Cerrar sesión',
        order: 'Pedido',
        dayExpires: 'Caduca el día',
        bookTheDay: 'Reserva el día',
        bookingByDate: 'Reservando por la fecha',
        expiredOnDay: 'Vale caducado el día',
        valExhausted: 'Vale agotado',
        of: 'de',
        calendar: 'Calendario',
        invoice: 'Factura',
        downloadInvoice: 'Descargar factura',
        viewOrder: 'Ver pedido',
        iForgotMyPassword: 'He olvidado mi contraseña',
        areYouANewCustomer: '¿Eres un cliente nuevo?',
        startHere: 'Empieza aquí',
        alreadyhaveACoupon: '¿Tienes un código de descuento?',
        putACode: 'Introduce el código',
    },
    actions: {
        continueShopping: 'Continuar comprando',
        add: 'Añadir',
        addToBasket: 'Añadir a la cesta',
        pay: 'Pagar',
        book: 'Reservar cita',
        retry: 'Reintentar',
        paymentRetry: 'Reintentar el pago',
        backToBasket: 'Volver a la cesta',
        checkVoucher: 'Comprobar código',
        cancel: 'Cancelar',
        readMore: 'Leer más',
        readLess: 'Leer menos',
        accept: 'Aceptar',
        login: 'Iniciar sesión',
        recoverAccount: 'Recuperar cuenta',
        forgottenPassword: '¿Has olvidado la contraseña?',
        register: 'Registrarse',
        send: 'Enviar',
        myAccount: 'Mi cuenta',
        check: 'Comprobar',
        toBook: 'Reserva',
        buy:'Compra',
        loginWithGoogle: 'Acceder con Google',
        loginWithFacebook: 'Acceder con Facebook',
        loginWithMicrosoft: 'Acceder con Microsoft',
        loginWithApple: 'Acceder con Apple',
        apply: 'Aplicar',
    },
    _eventStatus: {
        reservable: 'Reservable',
        exceptionally_closed: 'Excepcionalmente cerrado'
    },
    _orderStatus: {
        awaiting_payment: 'Pendiente',
        draft: 'Borrador',
        paid: 'Pagado',
        refunded: 'Reembolsado'
    },
    _paymentMethod: {
        null: '',
        cash: 'Efectivo',
        online: 'Online',
        card: 'Tarjeta',
        transfer: 'Transferencia',
    },
    paymentError: {
        null: 'Error desconocido',
        general_0: 'Las firmas no coinciden, posible fraude',
        redsys_0101: 'Tarjeta caducada',
        redsys_0102: 'Tarjeta en excepción transitoria o bajo sospecha de fraude',
        redsys_0106: 'Intentos de PIN excedidos',
        redsys_0125: 'Tarjeta no efectiva',
        redsys_0129: 'Código de seguridad (CVV2/CVC2) incorrecto',
        redsys_0180: 'Tarjeta ajena al servicio',
        redsys_0184: 'Error en la autenticación del titular',
        redsys_0190: 'Denegación del emisor sin especificar motivo',
        redsys_0191: 'Fecha de caducidad errónea',
        redsys_0195: 'Requiere autenticación SCA',
        redsys_0202: 'Tarjeta en excepción transitoria o bajo sospecha de fraude con retirada de tarjeta',
        redsys_0904: 'Comercio no registrado en FUC',
        redsys_0909: 'Error de sistema',
        redsys_0913: 'Pedido repetido',
        redsys_0944: 'Sesión incorrecta',
        redsys_0950: 'Operación de devolución no permitida',
        redsys_9912: 'Emisor no disponible',
        redsys_0912: 'Emisor no disponible',
        redsys_9064: 'Número de posiciones de la tarjeta incorrecto',
        redsys_9078: 'Tipo de operación no permitida para esa tarjeta',
        redsys_9093: 'Tarjeta no existente',
        redsys_9094: 'Rechazo de los servidores internacionales',
        redsys_9104: 'Comercio con titular seguro y titular sin clave de compra segura',
        redsys_9218: 'El comercio no permite op. seguras por entrada /operaciones',
        redsys_9253: 'Tarjeta no cumple el check-digit',
        redsys_9256: 'El comercio no puede realizar preautorizaciones',
        redsys_9257: 'Esta tarjeta no permite operativa de preautorizaciones',
        redsys_9261: 'Operación detenida por superar el control de restricciones en la entrada al SIS',
        redsys_9915: 'A petición del usuario se ha cancelado el pago',
        redsys_9997: 'Se está procesando otra transacción en SIS con la misma tarjeta',
        redsys_9998: 'Operación en proceso de solicitud de datos de tarjeta',
        redsys_9999: 'Operación que ha sido redirigida al emisor a autenticar',
    },
    forms:{
        required: 'Este campo es obligatorio',
        dninifcif: 'Este campo debe tener un formato de CIF/NIF/NIE válido.',
        email: 'Este campo debe tener un formato de email válido.',
        phone: 'El teléfono debe contener 9 dígitos sin espacios',
    },
    errors: {
        1000002: 'Error al iniciar sesión, Email o contraseña incorrectos.',
        1000030: 'El email no está validado.',
        1000032: 'El email {email} no existe. Porfavor regístrate.',
        1000011: 'El correo electrónico {email} ya está registrado. Porfavor inicia sesión.'
    }
};

Object.assign(lang.words, {
    Image: lang.words.image,
    Name: lang.words.name,
    Notes: lang.words.notes,
    Surnames: lang.words.surname,
    companyName: lang.words.company,
    contactEmail: lang.words.email,
    contactPhone: lang.words.phone,
    contactGender: lang.words.gender,
    contactBirthdate: lang.words.birthdate
});

export default lang;
