const lang = {
    words: {
        id: 'Id',
        license: 'License',
        username: 'Username',
        password: 'Password',
        rePassword: 'Repeat password',
        rememberMe: 'Remember me',
        basket: 'Basket',
        vat: 'VAT number',
        company: 'Company',
        name: 'Name',
        surname: 'Surname',
        email: 'Email',
        phone: 'Phone',
        street: 'Street',
        number: 'Number',
        city: 'City',
        state: 'State',
        postalCode: 'Postal code',
        country: 'Country',
        streetNumber: 'Number',
        total: 'Total',
        taxBase: 'Tax base',
        optional: 'Optional',
        attachments: 'Attachments',
        available: 'Available',
        product: 'Product',
        productsCount: ' 1 product | {count} products',
        generateInvoice: 'Request invoice (optional)',
        privacyPolicy: 'Privacy policy.',
        termsAndConditions: 'Legal notice.',
        beforeTermsAndConditions: 'I accept the',
        beforePrivacyPolicy: 'I accept the',
        beforePrivacyPolicys: 'I accept the',
        generalConditions: 'General conditions and terms of use',
        andThe: 'and the',
        voucher: 'Voucher',
        comment: 'Comment',
        code: 'Code',
        occupied: 'Occupied',
        maximum: 'Maximum',
        quant: 'Quant.',
        price: 'Price',
        minuts: 'Minuts',
        newCustomer: 'Are you a new customer?',
        startHere: 'Start here',
        registeredCustomer: 'Do you already have an account?',
        recoveryPassword: 'Enter the email address to retrieve your password',
        newPassword: 'Enter a new password',
        date: 'Date',
        status: 'Status',
        paymentDate: 'Payment date',
        paymentMethod: 'Payment method',
        orderDetails: 'Order details',
        refundDetails: 'Refund details',
        unitPrice: 'Unit price',
        quantity: 'Quantity',
        discount: 'Discount',
        edit: 'Edit',
        delete: 'Delete',
        save: 'Save',
        cancel: 'Cancel',
        close: 'Close',
        modify: 'Modify',
        privacyPolicyLink: '/sales-and-cancellation-return-policy/',
        termsAndConditionsText: 'terms and conditions',
    },
    messages: {
        loading: 'Loading...',
        xOfYAvailable: '{x} de {y} available',
        selectBookingdate: 'Select the reservation date',
        signinIn: 'Logging in',
        loginFail: 'Login unsuccessful',
        loginFailMessage: 'Check your credentials and retry',
        fileIsNotImage: 'This file is not an image',
        paymentOK: 'Payment successfully!',
        paymentKO: 'The payment could not be made.',
        paymentOKText: 'We will send the order confirmation to the email provided. See you soon.',
        iHaveATicketOrGiftVoucher: 'I have a ticket or gift voucher',
        writeHerePlaceholder: 'Write here ...',
        availableBookings: 'Available bookings',
        amountExceeded: 'Amount exceeded',
        amountExceededText: 'There are {maxUnits} reservations available for this date and you want to reserve {quantity}. If you need to make more reservations, you should find another date with more availability.',
        incorrectCode: 'Incorrect code',
        incorrectCodeText: 'Please check the code entered',
        validateYourEmail: 'Validate your email through the email you have received in your account.',
        registrationError: 'User could not be registered',
        registrationFail: 'Register unsuccessful',
        registrationFailMessage: 'Check your data and retry',
        customerAlreadyExists: 'Looks like you\'re already registered!',
        passwordConfirmationNotMatch: 'Password confirmation does not match',
        sentEmailRecovery: 'E-mail sent',
        sentEmailRecoveryText: 'We have sent you an email to recover your password.',
        sentEmailRecoveryFail: 'An error has occurred.',
        sentEmailRecoveryFailText: 'Try again later.',
        emailNotExists: 'E-mail does not exist.',
        emailAlreadyExists: 'E-mail already exists.',
        emptyBasket: 'There are no products in the cart.',
        noBookingPossible: 'No booking possible',
        theBookingOf: 'The booking of ',
        theDay: ' the day ',
        noFurtherBookingsAccepted: 'no further bookings can be made. Please book again at a different time.',
        errorCreatingOrder: 'Error creating order',
        thereSeemsToHaveAProblemWhenPlacingOrder: 'There seems to have been a problem placing your order. Please try again later or contact us.',
        bookingConfirmed: 'Booking confirmed',
        bookingIsConfirmedText: 'We confirm that your booking has been successfully registered.',
        giftVouchersAreIndividual: 'The gift voucher you are using is individual, you cannot use it for more than one booking in the same hour.',
        errorTryingToBook: 'Error trying to book',
        errorTryingToBookMessage: 'There was a problem while making this booking. Please try again later or contact us.',
        emptyOrders: 'No orders',
        emptyModifiableBookings: 'No modifiable bookings',
        cancellationRequest: 'To request the cancellation of an order, please contact us by email at',
        cancellationRequest2:  '(It will not be possible to cancel the visit before 24h).',
        soldOut: 'Sold out',
        lastUnits: 'Last units',
        available: 'Available',
        notModifiable: 'Not modifiable',
        bookingDateChanged: 'Booking date changed',
        chooseTheTourYouWantToTake: 'Have you been gifted a visit with us?',
        checkHereAndMakeYourReservation: 'I have a gift voucher',
        enterTheCode: 'Enter the code',
        highAvailavility: 'High availability',
        mediumAvailavility: 'Medium availability',
        noAvailavility: 'No availability',
        acceptTheTerms: 'Accept the terms',
        haveToAcceptTheTerms: 'To proceed with the purchase, you must accept the terms of sale and the privacy policy.',
        missingPaymentEntries: 'Missing payment entries',
        guidedTours: 'The booking of free guided tours is linked to the purchase of paid tickets. Please add paid tickets to the cart to proceed with the purchase.',
        ok: 'Okay',
        myProfile: 'My profile',
        modifyBookings: 'Modify bookings',
        myOrders: 'My orders',
        changePassword: 'Change password',
        logOut: 'Log out',
        order: 'Order',
        dayExpires: 'Expires on',
        bookTheDay: 'Book the day',
        bookingByDate: 'Booking by date',
        expiredOnDay: 'Voucher expired on',
        valExhausted: 'Voucher exhausted',
        of: 'of',
        calendar: 'Calendar',
        invoice: 'Invoice',
        downloadInvoice: 'Download invoice',
        viewOrder: 'View order',
        iForgotMyPassword: 'I forgot my password',
        areYouANewCustomer: 'Are you a new customer?',
        startHere: 'Start here',
        alreadyhaveACoupon: 'Do you already have a discount code?',
        putACode: 'Enter the code',
    },
    actions: {
        continueShopping: 'Continue shopping',
        add: 'Add',
        addToBasket: 'Add to basket',
        pay: 'Pay',
        book: 'Book',
        retry: 'Retry',
        paymentRetry: 'Payment retry',
        backToBasket: 'Back to basket',
        checkVoucher: 'Check voucher',
        cancel: 'Cancel',
        readMore: 'Read more',
        readLess: 'Read less',
        accept: 'Accept',
        login: 'Login',
        recoverAccount: 'Recover account',
        forgottenPassword: 'Have you forgotten the password?',
        register: 'Register',
        send: 'Send',
        myAccount: 'My account',
        check: 'Check',
        toBook: 'Book',
        buy:'Buy',
        loginWithGoogle: 'Login with Google',
        loginWithFacebook: 'Login with Facebook',
        loginWithMicrosoft: 'Login with Microsoft',
        loginWithApple: 'Login with Apple',
        apply: 'Apply',

    },
    _eventStatus: {
        reservable: 'Reservable',
        exceptionally_closed: 'Exceptionally closed'
    },
    _orderStatus: {
        awaiting_payment: 'Pending',
        draft: 'Draft',
        paid: 'Paid',
        refunded: 'Refunded'
    },
    _paymentMethod: {
        null: '',
        cash: 'Cash',
        online: 'Online',
        card: 'Card',
        transfer: 'Transfer',
    },
    paymentError: {
        null: 'Unknown error',
        general_0: 'The signatures do not match, possible fraud',
        redsys_0101: 'Expired card',
        redsys_0102: 'Card in transitory exception or under suspicion of fraud',
        redsys_0106: 'PIN attempts exceeded',
        redsys_0125: 'Ineffective card',
        redsys_0129: 'Security code (CVV2/CVC2) incorrect',
        redsys_0180: 'Non-service card',
        redsys_0184: 'Cardholder authentication error',
        redsys_0190: 'Refusal of the issuer without specifying reason',
        redsys_0191: 'Wrong expiration date',
        redsys_0195: 'Requires SCA authentication',
        redsys_0202: 'Card in temporary exception or under suspicion of fraud with card withdrawal',
        redsys_0904: 'Trade not registered in FUC',
        redsys_0909: 'System error',
        redsys_0913: 'Duplicate order',
        redsys_0944: 'Incorrect session',
        redsys_0950: 'Return operation not allowed',
        redsys_9912: 'Issuer not available',
        redsys_0912: 'Issuer not available',
        redsys_9064: 'Incorrect number of card positions',
        redsys_9078: 'Type of operation not allowed for that card',
        redsys_9093: 'Non-existing card',
        redsys_9094: 'Rejection from international servers',
        redsys_9104: 'Trade with a secure holder and holder without a secure purchase key',
        redsys_9218: 'Trade does not allow op. secure per entry/operations',
        redsys_9253: 'Card does not meet the check-digit',
        redsys_9256: 'The merchant cannot perform pre-authorizations',
        redsys_9257: 'This card does not allow pre-authorization operations',
        redsys_9261: 'Operation stopped for overcoming the restriction control at the entrance to the SIS',
        redsys_9915: 'At the request of the user the payment has been canceled',
        redsys_9997: 'Another transaction is being processed in SIS with the same card',
        redsys_9998: 'Card data request process operation',
        redsys_9999: 'Operation that has been redirected to the sender to authenticate',
    },
    forms:{
        required: 'This field is required.',
        dninifcif: 'This field must be a valid CIF/NIF/NIE',
        email: 'This field must be a valid email address',
        phone: 'The phone must contain 9 digits without spaces',
    },
    errors: {
        1000002: 'Login failed, wrong email or password.',
        1000030: 'The email is not validated.',
        1000032: 'Email {email} does not exist. Please sign in',
        1000011: 'Email {email} already registered. Please log in.'
    }
};

Object.assign(lang.words, {
    Image: lang.words.image,
    Name: lang.words.name,
    Notes: lang.words.notes,
    Surnames: lang.words.surname,
    companyName: lang.words.company,
    contactEmail: lang.words.email,
    contactPhone: lang.words.phone,
    contactGender: lang.words.gender,
    contactBirthdate: lang.words.birthdate
});

export default lang;
