<template>
  <div class="text-center">
    <div class="wdgt-image-wrapper"/>
    <div class="wdgt-name-coupon">
      <div class="wdgt-name">
        {{ $t('messages.chooseTheTourYouWantToTake') }}
      </div>
      <div v-show="!showForm" class="wdgt-button">
        <button type="button" @click.prevent="toggleShowForm" class="voucher-show-form voucher-code-check-button">{{$t('messages.checkHereAndMakeYourReservation')}}</button>
      </div>
    </div>
    <div v-show="showForm" class="voucher-form-container">
      <div class="wdgt-description-input">
        <input v-model="voucherCode" type="text" class="voucher-code-check-input" :placeholder="$t('messages.enterTheCode')" @keypress.enter="checkVoucher">
      </div>
      <div class="wdgt-buttons">
        <button type="button" class="voucher-code-check-button" @click="checkVoucher">
          {{$t('actions.check')}}
        </button>
      </div>
    </div>
    <modal v-if="voucher" hide-footer size="xl" id="modal-voucher" @close="voucher = null" >
      <h3 slot="header">{{$t('messages.order')}}</h3>
      <div slot="body">
          <div v-if="!selectedProduct" >
              <h2 class="text-center">
                  {{ $t('words.voucher') }} {{ voucher.code }}
              </h2>
              <p class="text-center text-bold">{{ voucher.orderDetail.product.name }}</p>
              <p class="text-center">{{$t('messages.dayExpires')}} {{ voucher.expiresAt | toDate | dateFormat }}</p>
              <div v-if="!isExpired()" class="text-center" style="margin-bottom: 30px">
                  {{ $t('messages.availableBookings') }}: {{ availableBookings }} de {{ voucher.maximumNumberOfBookings }}<br>
              </div>
              <div v-for="bookingOrderDetail in voucher.bookings" :key="bookingOrderDetail.id" class="text-center">
                  {{$t('messages.bookTheDay')}} {{ bookingOrderDetail.startsAt | toDate | dateTimeFormat }}
              </div>
              <div v-if="selectedSlot" style="margin-top: 30px;" class="text-center">

                  <div style="margin-bottom: 20px"><b>{{$t('messages.bookingByDate')}}:</b> {{selectedSlot.startDate | dateTimeFormat}}</div>
                  <div>
                      <div class="form-field">
                          <label for="wdgt-notes-field">{{ $t('words.comment') }}</label><br>
                          <textarea id="wdgt-notes-field" v-model="notes"></textarea>
                      </div>
                  </div>
                  <button type="button" class="secondary" @click="selectedSlot = null" style="margin-right: 30px">
                      {{ $t('actions.cancel') }}
                  </button>
                  <button type="button" @click="sendBooking">
                      {{ $t('actions.book') }}
                  </button>
              </div>
              <div v-else-if="availableBookings && !isExpired()" class="text-center" style="margin-top: 30px;">
                  <button type="button" class="secondary" @click="voucher = null" style="margin-right: 30px">
                      {{ $t('actions.cancel') }}
                  </button>
                  <button type="button" @click="openCalendar">
                      {{ $t('actions.book') }}
                  </button>
              </div>
              <div v-else-if="isExpired()" class="text-center">
                  <p style="font-weight: bold; color: #c91919">{{$t('messages.expiredOnDay').toUpperCase() }} {{ voucher.expiresAt | toDate | dateFormat }}</p>
                  <button type="button" class="secondary" @click="voucher = null">
                      {{ $t('actions.cancel') }}
                  </button>
              </div>
              <div v-else class="text-center">
                  <p style="font-weight: bold; color: #c91919">{{$t('messages.valExhausted').toUpperCase() }}</p>
                  <button type="button" class="secondary" @click="voucher = null">
                      {{ $t('actions.cancel') }}
                  </button>
              </div>
          </div>
          <SimpleCalendar v-else :product="selectedProduct" :gift-voucher="voucher" @bookingSlotSelected="handleBookingSlotSelected" @cancel="handleCancel" />
      </div>
    </modal>
  </div>
</template>

<script>
import GiftVoucherRepository from "@/api/GiftVoucherRepository";
import SimpleCalendar from '@/components/castelldelremei/simpleCalendar';
import Modal from '@/components/modal';
import SweetAlert from "sweetalert2";
import {i18n} from "@/lang";

export default {
  components: { SimpleCalendar, Modal },
  data () {
    return {
      voucher: null,
      voucherCode: null,
      selectedProduct: null,
      selectedSlot: null,
      notes: '',
      showForm: false
    }
  },
  computed: {
    availableBookings () {
      return this.voucher.maximumNumberOfBookings - this.voucher.bookings.length
    }
  },
  methods: {
    async checkVoucher() {
      try {
        if (this.voucherCode.length <= 5) {
          throw true;
        }
        const response = await GiftVoucherRepository.checkVoucher(this.voucherCode)
        this.voucher = response.data;
      } catch (err) {
        await SweetAlert.fire({
          title : this.$t('messages.incorrectCode'),
          html :  this.$t('messages.incorrectCodeText'),
          icon : 'info',
          iconColor : '#c91919',
          confirmButtonColor: '#c91919'
        })
      }
    },
    isExpired() {
      return (new Date(this.voucher.expiresAt) <= new Date());
    },
    openCalendar () {
      this.selectedProduct = this.voucher.orderDetail.product
    },
    handleBookingSlotSelected (slot) {
      this.selectedSlot = slot
      this.selectedProduct = null
    },
    handleCancel () {
      this.selectedProduct = null
    },
    async sendBooking () {
      await GiftVoucherRepository.bookVoucher(
          this.voucher.code,
          this.selectedSlot.startDate,
          this.notes
      ).then((response) => {
        for(const booking of response.data) {
          this.voucher.bookings.push(booking);
        }
        this.selectedProduct = null;
        this.selectedSlot = null;
        SweetAlert.fire({
          title : this.$t('messages.bookingConfirmed'),
          html : this.$t('messages.bookingIsConfirmedText'),
          icon : 'success'
        })
      }).catch((error) => {
        if (error.response.data.code === 1000007) {
          SweetAlert.fire({
            title: i18n.t('messages.noBookingPossible'),
            html:  i18n.t('messages.giftVouchersAreIndividual'),
            icon: 'info'
          });
        } else {
          SweetAlert.fire({
            title: i18n.t('messages.errorTryingToBook'),
            html:  i18n.t('messages.errorTryingToBookMessage'),
            icon: 'error'
          });
        }
      })
    },
    toggleShowForm() {
      this.showForm = !this.showForm;
    }
  }
}
</script>

<style scoped>
button {
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 6px 12px;
  background: #c91919;
}
button.secondary {
  background: #939393;
}
.voucher-code-check-input {
  margin-top: 20px;
  background-color: transparent !important;
  border: 0;
  border-bottom: 1px solid #CCA20E !important;
  color: #676767 !important;
  font-size: 1rem;
}

.voucher-code-check-input::placeholder {
  text-align: center;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.voucher-code-check-button {
  background-color: #CCA20E;
  color: #FFFFFF;
  margin-top: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border-right: 4px;
  padding: 15px 21px 15px 23px;
  justify-content: center;
  align-items: center;
  font-weight: normal !important;
}
.wdgt-name {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #676767;
}

.wdgt-button {
  text-align:center;
}
</style>
